import React from 'react';
import './iOSButton.css'; // Make sure to create this CSS file for styling

const IosButton = ({ children, onClick }) => {
  return (
    <button className="ios-button" onClick={onClick}>
      <b>{children}</b>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
      </svg>
    </button>
  );
};

export default IosButton;
