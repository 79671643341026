import IosButton from './iOSButton';
import './App.css'; // Custom CSS

function Navbar({ isSupportPage = false }) {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">
          {"YesPass"}
          {isSupportPage && (
            <span style={{
              WebkitTextFillColor: 'white',
              WebkitTextStroke: '1px black'
            }}>
              {" Support"}
            </span>
          )}
        </a>
      </div>
      <ul className="navbar-links">
        <li><a href="#section1">Home</a></li>
        <li><a href="#section2">Features</a></li>
        <li><a href="#section3">About</a></li>
        <IosButton onClick={() => alert('Button clicked!')} className="kicsi-btn">
          Get Started for Free
        </IosButton>
      </ul>
    </nav>
  );
}
export default Navbar;
