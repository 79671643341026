import React from 'react';
import './App.css'; // Custom CSS
import IosButton from './iOSButton';
import { IPhoneMockup } from 'react-device-mockup';
import gif from './login.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faShieldAlt, faSearch, faCloudUploadAlt, faDatabase, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Support from './Support'; // Your other component
import Mainapp from './Main';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">YesPass</a>
      </div>
      <ul className="navbar-links">
        <li><a href="#section1">Home</a></li>
        <li><a href="#section2">Features</a></li>
        <li><a href="#section3">About</a></li>
        <li><a href="#section4">Contact</a></li>
      </ul>
    </nav>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Mainapp />} />
          <Route path="/Support" element={<Support />} />
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;
